import { E_GlobalDomains, ManageDomains, RegionToManageDomains } from "./constants";

export function getGlobalDomain(isProd: boolean): string {
  return isProd ? E_GlobalDomains.PRODUCTION : E_GlobalDomains.SANDBOX;
}

export function getShortCodeDomain(domain: string, isProd: boolean): string {
  if (!domain.endsWith(E_GlobalDomains.PRODUCTION)) {
    return domain;
  }

  return getGlobalDomain(isProd);
}

export function getManageDomains(region: string): (typeof ManageDomains)[keyof typeof ManageDomains] {
  return RegionToManageDomains[region];
}
