import { Injectable } from '@angular/core';
import { E_ResourceType } from '@backend/common/schema';
import { CommonService } from './common.service';
import { HttpService } from './http.service';
import { JWTService } from './jwt.service';
import { E_GlobalDomains } from '@shared/constants';

@Injectable({ providedIn: 'root' })
export class DevService {
  private _currentObject: any = null;
  private _currentObjectType: string | null = null;
  private _isImpersonationVisible = true;
  private _isDiagnosticsVisible = false;

  constructor(private _jwtService: JWTService, private _httpService: HttpService, private _commonService: CommonService) {}

  public get isImpersonationVisible(): boolean {
    if (!this.isUserImpersonating) return false;
    return this._isImpersonationVisible;
  }

  public set isImpersonationVisible(value: boolean) {
    this._isImpersonationVisible = value;
  }

  public get isDiagnosticsVisible(): boolean {
    if (!this.isUserImpersonating) return false;
    return this._isDiagnosticsVisible;
  }

  public set isDiagnosticsVisible(value: boolean) {
    this._isDiagnosticsVisible = value;
  }

  public get isUserImpersonating(): boolean {
    return this._jwtService.getJWT().is_impersonating;
  }

  public get isUserDev(): boolean {
    return this._jwtService.getJWT().permission_level === 5;
  }

  public get isUserLocalDev(): boolean {
    return window.location.hostname.endsWith(E_GlobalDomains.SANDBOX) && this.isUserDev;
  }

  public get shouldShowSparkles(): boolean {
    return this.isUserDev || (this.isUserImpersonating && this._isDiagnosticsVisible);
  }

  public clearObject() {
    this._currentObjectType = '';
    this._currentObject = null;
  }

  public getObject(): { objectType: string | null; object: any } {
    return { objectType: this._currentObjectType, object: this._currentObject };
  }

  // eslint-disable-next-line complexity
  public async setObjectDetails(objectId: string, objectType: string) {
    let query = '';

    switch (objectType) {
      case E_ResourceType.AppointmentTypeCategory:
        query = `{
          practice {
            appointment_type_category(appointment_type_category_id: "${objectId}") {
              id
              name
              description
              icon
              new_text_template
              order
              for_new_patients
              for_lapsed_patients
              for_existing_patients
              dental_appointment
              hygiene_appointment
              always_allow_any_practitioner
            }
          }
        }`;
        break;

      case E_ResourceType.AppointmentType:
        query = `{
          practice {
            appointment_type(appointment_type_id: "${objectId}") {
              id
              practice_id
              name
              description
              reason
              appointment_type_category_id
              payment_provider_id
              consultation_type
            }
          }
        }`;
        break;

      case E_ResourceType.Brand:
        query = `{
          practice {
            brand(brand_id:"${objectId}") {
              id
              colour
              default_uri
              default_url
              display_name
              ga_tracking_cookie_domain
              ga_tracking_id
              ga_tracking_link_domain
              logo_url
              owner
              owning_site_id
              owning_site {
                name
              }
              privacy_policy_url
              subdomain
              uri
              url
              applied_at_sites {
                items {
                  id
                  name
                }
              }
            }
          }
        }`;
        break;

      case E_ResourceType.Features:
        query = `{
          practice {
            features {
              branding
              devices
              l3_login
              online_booking
              online_signing
              payments
              practitioner_profiles
            }
          }
        }`;
        break;

      case E_ResourceType.LoggedInUser:
        query = `{
          logged_in_user {
            first_name
            image_url
            last_name
            role
            title
            created_at
            email
            intercom_hash
          }
          practice {
            user(user_id:"${this._jwtService.getJWT().user_id}") {
              allowed_sites {
                items {
                  site_id
                }
              }
              permission_level
              practitioners {
                items {
                  active
                  site_id
                  site {
                    name
                  }
                }
              }
            }
          }
        }`;
        break;

      case E_ResourceType.Patient:
        query = `{
          find_patients(search_criteria:"${objectId}") {
            items {
              id
              account_id
              active
              age
              bad_debtor
              date_of_birth
              dentist_id
              email_address
              ethnicity
              first_appointment_date
              first_exam_date
              first_name
              gender
              hygienist_id
              is_nhs
              last_appointment_date
              last_cancelled_appointment_date
              last_exam_date
              last_fta_appointment_date
              last_impersonator_login
              last_name
              last_patient_login
              last_scale_and_polish_date
              mobile_phone
              mobile_phone_country
              mobile_phone_normalized
              next_appointment_date
              next_exam_date
              next_scale_and_polish_date
              nhs_country
              nhs_exemption_code
              nhs_number
              ni_number
              payment_plan_id
              portal_url
              portal_url_is_old_world
              prevent_appointment_booking
              registered_portal_user
              site_id
              use_email
              use_sms

              address_line_1
              address_line_2
              address_line_3
              county
              town
              postcode
            }
          }
        }`;
        break;

      case 'PatientNotificationSettings':
        query = `{
          practice {
            site(site_id:"${this._commonService.selectedSiteId}") {
              settings {
                comms__booking_confirmation__patient_email
                comms__booking_confirmation__patient_sms
                comms__booking_cancellation__patient_email
                comms__booking_cancellation__patient_sms
              }
            }
          }
        }`;
        break;

      case E_ResourceType.Practice:
        query = `{
          practice {
            id
            currency
            currency_code
            dentally_practice_id
            dial_code
            is_multisite
            iso_country_code
            last_refresh_time
            logo_url
            requires_migration
            medical_history_expiry_days
            name
            nhs
            nhs_country
            nhs_payment_plan_id
            opening_hours
            patient_email_address
            privacy_policy_url
            phone_number
            refresh_status
            slug
            time_zone
            website

            address_line_1
            address_line_2
            address_line_3
            county
            town
            postcode
          }
        }`;
        break;

      case 'PracticeAppointmentSettings':
        query = `{
          practice {
            appointment_settings {
              practice_id
              allow_appointment_cancellations
              show_all_slots_new_patients
              required_to_select_site
              show_all_slots_existing_patients
              min_wait_time
              max_wait_time
              max_days_ahead_bookings
              lapsed_dental_duration
              lapsed_hygiene_duration
              hygiene_lapsing_exam_types
              can_book_together
            }
          }
        }`;
        break;

      case 'PracticeBrands':
        query = `{
            practice {
            owned_brands {
              items {
                id
                colour
                default_uri
                default_url
                display_name
                logo_url
                subdomain
              }
            }
          }
        }`;
        break;

      case 'PracticeNotificationSettings':
        query = `{
          practice {
            site(site_id:"${this._commonService.selectedSiteId}") {
              settings {
                comms__booking_new_patient__practice_email
                comms__booking_existing_patient__practice_email
                comms__booking_cancellation__practice_email
                comms__booking_patient_comment__practice_email
                comms__task_reminders__patient_email
                comms__task_reminders__patient_sms
              }
            }
          }
        }`;
        break;

      case E_ResourceType.PracticeSettings:
        query = `{
          practice {
            settings {
              l3_permission__can_edit_appointment_settings
              l3_permission__can_edit_appointments
              l3_permission__can_edit_customise_portal
              l3_permission__can_edit_customise_practitioners
              l3_permission__can_edit_online_signing
              l3_permission__can_edit_patient_notifications
              l3_permission__can_edit_payment_plans
              l3_permission__can_edit_payment_types
              l3_permission__can_edit_practice_notifications
              l3_permission__can_edit_task_reminder_notifications
              l3_permission__can_edit_patient_profile
              l2_permission__can_unlock_device
              l2_permission__can_pair_device
              l1_permission__can_unlock_device
              l1_permission__can_pair_device
            }
          }
        }`;
        break;

      case E_ResourceType.Practitioner:
        query = `{
          practice {
            practitioner(practitioner_id:"${objectId}") {
              id
              site_id
              site {
                name
              }
              active
              colour
              user_id
              full_name
              user {
                uuid
                id
                created_at
                first_name
                image_url
                last_login
                last_name
                permission_level
                role
                title
                allowed_sites {
                  items {
                    site_id
                    site {
                      name
                    }
                  }
                }
              }
              job_title
              qualifications
              biography
              image_url
              gdc_number
              site_appointment_type_durations {
                items {
                  site_appointment_type {
                    appointment_type {
                      name
                    }
                  }
                }
              }
            }
          }
        }`;
        break;

      case E_ResourceType.Site:
        query = `{
          practice {
            site(site_id:"${objectId}") {
              id
              applied_brand_id
              applied_brand {
                display_name
                uri
              }
              active
              active_mailbox_address
              email_address
              logo_url
              name
              nhs_country
              nickname
              opening_hours
              phone_number
              website

              address_line_1
              address_line_2
              address_line_3
              county
              town
              postcode
            }
          }
        }`;
        break;

      case 'SiteAppliedBrand':
        query = `{
          practice {
            site(site_id:"${this._commonService.selectedSiteId}") {
              applied_brand {
                id
                colour
                default_uri
                default_url
                display_name
                ga_tracking_cookie_domain
                ga_tracking_id
                ga_tracking_link_domain
                logo_url
                privacy_policy_url
                subdomain
                uri
                url
              }
            }
          }
        }`;
        break;

      case E_ResourceType.SiteAppointmentType:
        query = `{
          practice {
            site_appointment_type(site_appointment_type_id:"${objectId}") {
              id
              appointment_type_id
              appointment_type {
                name
              }
              site_id
              site {
                name
              }
              is_enabled
              use_free_time
              duration
              deposit
              price
              bookable_sessions {
                items {
                  recurring_appointment_id
                  recurring_appointment {
                    title
                  }
                }
              }
              practitioner_durations {
                items {
                  practitioner_id
                  full_name
                  practitioner {
                    user {
                      id
                      first_name
                      last_name
                    }
                  }
                  overridden_duration
                }
              }
            }
          }
        }`;
        break;

      case 'SiteNotifications':
        query = `{
            practice {
              site(site_id:"${this._commonService.selectedSiteId}") {
                settings {
                  comms__booking_new_patient__practice_email
                  comms__booking_existing_patient__practice_email
                  comms__booking_cancellation__practice_email
                  comms__booking_patient_comment__practice_email
                  comms__booking_confirmation__patient_email
                  comms__booking_confirmation__patient_sms
                  comms__booking_cancellation__patient_email
                  comms__booking_cancellation__patient_sms
                  comms__task_reminders__patient_email
                  comms__task_reminders__patient_sms
                }
              }
            }
          }`;
        break;

      case E_ResourceType.SitePaymentPlanCategory:
        query = `{
          practice {
            site(site_id: "${this._commonService.selectedSiteId}") {
              site_payment_plan_categories(filters:[{ field: "id" value: "${objectId}" }]) {
                items {
                  allow_any_practitioner
                  description
                  icon
                  id
                  subtitle
                  title
                }
              }
            }
          }
        }`;
        break;

      case 'SitePaymentSettings':
        query = `{
            practice {
              site(site_id:"${this._commonService.selectedSiteId}") {
                settings {
                  payments__balance_payments
                }
              }
            }
          }`;
        break;

      case 'SiteSigningSettings':
        query = `{
          practice {
            site(site_id:"${this._commonService.selectedSiteId}") {
              settings {
                forms__nhs_pr
                forms__medical_history
                forms__treamtent_plan_estimates
              }
            }
          }
        }`;
        break;

      case E_ResourceType.SitePaymentPlan:
        query = `{
          practice {
            site(site_id: "${this._commonService.selectedSiteId}") {
              site_payment_plan(site_payment_plan_id: "${objectId}") {
                id
                monthly_price
                require_deposit
                allow_any_practitioner
                allowances {
                  items {
                    amount
                    interval
                    site_appointment_type_id
                  }
                }
                discounts {
                  items {
                    percentage
                    appointment_type_category_id
                  }
                }
              }
            }
          }
        }`;
        break;

      default:
        return;
    }

    if (!query) return;

    const response = await this._httpService.fetchData<any>(query);
    let object: any = null;

    switch (objectType) {
      case E_ResourceType.AppointmentTypeCategory:
        object = response.practice.appointment_type_category;
        break;

      case E_ResourceType.AppointmentType:
        object = response.practice.appointment_type;
        break;

      case E_ResourceType.Brand:
        object = response.practice.brand;
        break;

      case E_ResourceType.Features:
        object = response.practice.features;
        break;

      case E_ResourceType.LoggedInUser:
        object = response.logged_in_user;
        object.permission_level = this._jwtService.getJWT().permission_level;
        object.id = this._jwtService.getJWT().user_id;
        object.user = response.practice.user;
        break;

      case 'Patient':
        object = response.find_patients.items[0];
        break;

      case E_ResourceType.Practice:
        object = response.practice;
        break;

      case 'PracticeAppointmentSettings':
        object = response.practice.appointment_settings;
        break;

      case 'PracticeBrands':
        object = response.practice.owned_brands;
        break;

      case 'PatientNotificationSettings':
      case 'PracticeNotificationSettings':
        object = response.practice.site.settings;
        break;

      case E_ResourceType.PracticeSettings:
        object = response.practice.settings;
        break;

      case E_ResourceType.Practitioner:
        object = response.practice.practitioner;
        break;

      case E_ResourceType.Site:
        object = response.practice.site;
        break;

      case 'SiteAppliedBrand':
        object = response.practice.site.applied_brand;
        break;

      case E_ResourceType.SiteAppointmentType:
        object = response.practice.site_appointment_type;
        break;

      case 'SiteNotifications':
        object = response.practice.site.settings;
        break;

      case E_ResourceType.SitePaymentPlan:
        object = response.practice.site.site_payment_plan;
        break;

      case E_ResourceType.SitePaymentPlanCategory:
        object = response.practice.site.site_payment_plan_categories.items[0];
        break;

      case 'SiteSigningSettings':
      case 'SitePaymentSettings':
        object = response.practice.site.settings;
        break;

      default:
        return;
    }

    this._currentObject = object;
    this._currentObjectType = objectType;
  }

  public getObjectHistory(): { objectType: string | null; object: any } {
    return { objectType: this._currentObjectType, object: this._currentObject };
  }

  // eslint-disable-next-line complexity
  public async setObjectHistory(objectId: string, objectType: string) {
    this._currentObjectType = objectType;

    let query: string;

    switch (objectType) {
      case E_ResourceType.AppointmentType:
        query = `{
          practice {
            appointment_type(appointment_type_id: "${objectId}") {
              all_history {
                ID: id
                Name: name
                Description: description
                Reason: reason
                Category: appointment_type_category{
                  name
                }
                Payment_Provider: payment_provider_id
                Consultation_Type: consultation_type

                Audit_ID: audit_id
                Audit_Action: audit_action
                Audit_Timestamp: audit_timestamp
                Audit_Author: audit_author
                Audit_Impersonator: audit_impersonator
              }
            }
          }
        }`;
        break;

      case E_ResourceType.AppointmentTypeCategory:
        query = `{
          practice {
            appointment_type_category(appointment_type_category_id: "${objectId}") {
              all_history {
                id
                name
                description
                icon
                new_text_template
                order
                for_new_patients
                for_lapsed_patients
                for_existing_patients
                dental_appointment
                hygiene_appointment
                always_allow_any_practitioner

                Audit_ID: audit_id
                Audit_Action: audit_action
                Audit_Timestamp: audit_timestamp
                Audit_Author: audit_author
                Audit_Impersonator: audit_impersonator
              }
            }
          }
        }`;
        break;

      case E_ResourceType.Brand:
        query = `{
          practice {
            brand(brand_id:"${objectId}") {
              all_history {
                id
                colour
                display_name
                ga_tracking_cookie_domain
                ga_tracking_id
                ga_tracking_link_domain
                logo_url
                privacy_policy_url
                uri

                Audit_ID: audit_id
                Audit_Action: audit_action
                Audit_Timestamp: audit_timestamp
                Audit_Author: audit_author
                Audit_Impersonator: audit_impersonator
              }
            }
          }
        }`;
        break;

      case E_ResourceType.Features:
        query = `{
          practice {
            features {
              all_history {
                branding
                devices
                l3_login
                online_booking
                online_signing
                payments
                practitioner_profiles

                Audit_ID: audit_id
                Audit_Action: audit_action
                Audit_Timestamp: audit_timestamp
                Audit_Author: audit_author
                Audit_Impersonator: audit_impersonator
              }
            }
          }
        }`;
        break;

      case 'PatientNotificationSettings':
        query = `{
          practice {
            site(site_id:"${this._commonService.selectedSiteId}") {
              settings {
                all_history {
                  Booking_confirmation_email: comms__booking_confirmation__patient_email
                  Booking_confirmation_SMS: comms__booking_confirmation__patient_sms
                  Booking_cancellation_email: comms__booking_cancellation__patient_email
                  Booking_cancellation_SMS: comms__booking_cancellation__patient_sms
                  Task_reminder_email: comms__task_reminders__patient_email
                  Task_reminder_sms: comms__task_reminders__patient_sms

                  Audit_ID: audit_id
                  Audit_Action: audit_action
                  Audit_Timestamp: audit_timestamp
                  Audit_Author: audit_author
                  Audit_Impersonator: audit_impersonator
                }
              }
            }
          }
        }`;
        break;

      case E_ResourceType.Practice:
        query = `{
          practice {
            all_history {
              id
              currency
              currency_code
              dentally_practice_id
              dial_code
              iso_country_code
              logo_url
              name
              nhs
              nhs_country
              nhs_payment_plan_id
              opening_hours
              patient_email_address
              privacy_policy_url
              phone_number
              slug
              time_zone
              website

              address_line_1
              address_line_2
              address_line_3
              county
              town
              postcode

              Audit_ID: audit_id
              Audit_Action: audit_action
              Audit_Timestamp: audit_timestamp
              Audit_Author: audit_author
              Audit_Impersonator: audit_impersonator
            }
          }
        }`;
        break;

      case 'PracticeAppointmentSettings':
        query = `{
          practice {
            appointment_settings {
              all_history {
                practice_id
                allow_appointment_cancellations
                show_all_slots_new_patients
                required_to_select_site
                show_all_slots_existing_patients
                min_wait_time
                max_wait_time
                max_days_ahead_bookings
                lapsed_dental_duration
                lapsed_hygiene_duration
                hygiene_lapsing_exam_types
                can_book_together

                Audit_ID: audit_id
                Audit_Action: audit_action
                Audit_Timestamp: audit_timestamp
                Audit_Author: audit_author
                Audit_Impersonator: audit_impersonator
              }
            }
          }
        }`;
        break;

      case 'PracticeNotificationSettings':
        query = `{
          practice {
            site(site_id:"${this._commonService.selectedSiteId}") {
              settings {
                all_history {
                  New_patient_booking: comms__booking_new_patient__practice_email
                  Existing_patient_booking: comms__booking_existing_patient__practice_email
                  Booking_cancellation: comms__booking_cancellation__practice_email
                  Booking_patient_comment: comms__booking_patient_comment__practice_email

                  Audit_ID: audit_id
                  Audit_Action: audit_action
                  Audit_Timestamp: audit_timestamp
                  Audit_Author: audit_author
                  Audit_Impersonator: audit_impersonator
                }
              }
            }
          }
        }`;
        break;

      case E_ResourceType.PracticeSettings:
        query = `{
          practice {
            settings {
              all_history {
                l3_permission__can_edit_appointment_settings
                l3_permission__can_edit_appointments
                l3_permission__can_edit_customise_portal
                l3_permission__can_edit_customise_practitioners
                l3_permission__can_edit_online_signing
                l3_permission__can_edit_patient_notifications
                l3_permission__can_edit_payment_plans
                l3_permission__can_edit_payment_types
                l3_permission__can_edit_practice_notifications
                l3_permission__can_edit_task_reminder_notifications
                l3_permission__can_edit_patient_profile
              }
            }
          }
        }`;
        break;

      case E_ResourceType.Practitioner:
        query = `{
          practice {
            practitioner(practitioner_id:"${objectId}") {
              all_history {
                id
                site_id
                site {
                  name
                }
                active
                colour
                user_id
                full_name
                user {
                  id
                  created_at
                  first_name
                  image_url
                  last_login
                  last_name
                  permission_level
                  role
                  title
                }
                job_title
                qualifications
                biography
                image_url
                gdc_number

                Audit_ID: audit_id
                Audit_Action: audit_action
                Audit_Timestamp: audit_timestamp
                Audit_Author: audit_author
                Audit_Impersonator: audit_impersonator
              }
            }
          }
        }`;
        break;

      case E_ResourceType.Site:
        query = `{
          practice {
            site(site_id:"${objectId}") {
              all_history {
                id
                applied_brand_id
                applied_brand {
                  display_name
                  uri
                }
                active
                active_mailbox_address
                email_address
                logo_url
                name
                nhs_country
                nickname
                opening_hours
                phone_number
                start_date
                stripe_account_id
                website

                address_line_1
                address_line_2
                address_line_3
                county
                town
                postcode

                Audit_ID: audit_id
                Audit_Action: audit_action
                Audit_Timestamp: audit_timestamp
                Audit_Author: audit_author
                Audit_Impersonator: audit_impersonator
              }
            }
          }
        }`;
        break;

      case 'SiteAppliedBrand':
        query = `{
          practice {
            site(site_id:"${this._commonService.selectedSiteId}") {
              applied_brand {
                all_history {
                  id
                  colour
                  default_uri
                  default_url
                  display_name
                  ga_tracking_cookie_domain
                  ga_tracking_id
                  ga_tracking_link_domain
                  logo_url
                  privacy_policy_url
                  uri

                  Audit_ID: audit_id
                  Audit_Action: audit_action
                  Audit_Timestamp: audit_timestamp
                  Audit_Author: audit_author
                  Audit_Impersonator: audit_impersonator
                }
              }
            }
          }
        }`;
        break;

      case E_ResourceType.SiteAppointmentType:
        query = `{
          practice {
            site_appointment_type(site_appointment_type_id:"${objectId}") {
              all_history {
                id
                appointment_type_id
                appointment_type {
                  name
                }
                site_id
                site {
                  name
                }
                is_enabled
                use_free_time
                duration
                deposit
                price

                Audit_ID: audit_id
                Audit_Action: audit_action
                Audit_Timestamp: audit_timestamp
                Audit_Author: audit_author
                Audit_Impersonator: audit_impersonator
              }
            }
          }
        }`;
        break;

      case E_ResourceType.SitePaymentPlan:
        query = `{
          practice {
            site(site_id: "${this._commonService.selectedSiteId}") {
              site_payment_plan(site_payment_plan_id: "${objectId}") {
                all_history {
                  id
                  monthly_price
                  require_deposit
                  allow_any_practitioner
                  payment_plan {
                    name
                  }

                  Audit_ID: audit_id
                  Audit_Action: audit_action
                  Audit_Timestamp: audit_timestamp
                  Audit_Author: audit_author
                  Audit_Impersonator: audit_impersonator
                }
              }
            }
          }
        }`;
        break;

      case E_ResourceType.SitePaymentPlanCategory:
        query = `{
          practice {
            site(site_id: "${this._commonService.selectedSiteId}") {
              site_payment_plan_categories(filters:[{ field: "id" value: "${objectId}" }]) {
                items {
                  all_history {
                    allow_any_practitioner
                    description
                    icon
                    id
                    subtitle
                    title

                    Audit_ID: audit_id
                    Audit_Action: audit_action
                    Audit_Timestamp: audit_timestamp
                    Audit_Author: audit_author
                    Audit_Impersonator: audit_impersonator
                  }
                }
              }
            }
          }
        }`;
        break;
      case 'SitePaymentSettings':
        query = `{
          practice {
            site(site_id:"${this._commonService.selectedSiteId}") {
              settings {
                all_history {
                  payments__balance_payments

                  Audit_ID: audit_id
                  Audit_Action: audit_action
                  Audit_Timestamp: audit_timestamp
                  Audit_Author: audit_author
                  Audit_Impersonator: audit_impersonator
                }
              }
            }
          }
        }`;
        break;

      case 'SiteSigningSettings':
        query = `{
          practice {
            site(site_id:"${this._commonService.selectedSiteId}") {
              settings {
                all_history {
                  forms__nhs_pr
                  forms__medical_history
                  forms__treamtent_plan_estimates

                  Audit_ID: audit_id
                  Audit_Action: audit_action
                  Audit_Timestamp: audit_timestamp
                  Audit_Author: audit_author
                  Audit_Impersonator: audit_impersonator
                }
              }
            }
          }
        }`;
        break;

      default:
        return;
    }

    const response = await this._httpService.fetchData<any>(query);

    switch (objectType) {
      case E_ResourceType.AppointmentType:
        this._currentObject = response.practice.appointment_type.all_history;
        break;

      case E_ResourceType.AppointmentTypeCategory:
        this._currentObject = response.practice.appointment_type_category.all_history;
        break;

      case E_ResourceType.Brand:
        this._currentObject = response.practice.brand.all_history;
        break;

      case E_ResourceType.Features:
        this._currentObject = response.practice.features.all_history;
        break;

      case E_ResourceType.Practice:
        this._currentObject = response.practice.all_history;
        break;

      case 'PracticeAppointmentSettings':
        this._currentObject = response.practice.appointment_settings.all_history;
        break;

      case 'PatientNotificationSettings':
      case 'PracticeNotificationSettings':
        this._currentObject = response.practice.site.settings.all_history;
        break;

      case E_ResourceType.PracticeSettings:
        this._currentObject = response.practice.settings.all_history;
        break;

      case E_ResourceType.Practitioner:
        this._currentObject = response.practice.practitioner.all_history;
        break;

      case E_ResourceType.Site:
        this._currentObject = response.practice.site.all_history;
        break;

      case 'SiteAppliedBrand':
        this._currentObject = response.practice.site.applied_brand.all_history;
        break;

      case E_ResourceType.SiteAppointmentType:
        this._currentObject = response.practice.site_appointment_type.all_history;
        break;

      case E_ResourceType.SitePaymentPlan:
        this._currentObject = response.practice.site.site_payment_plan.all_history;
        break;

      case E_ResourceType.SitePaymentPlanCategory:
        this._currentObject = response.practice.site.site_payment_plan_categories.items[0].all_history;
        break;

      case 'SitePaymentSettings':
      case 'SiteSigningSettings':
        this._currentObject = response.practice.site.settings.all_history;
        break;

      default:
        return;
    }
  }

  public async queueBulkCreate(config: any): Promise<string> {
    const query = `
      mutation {
        queueBulkCreate(config: ${config})
      }`;

    return (await this._httpService.fetchData<any>(query)).queueBulkCreate;
  }

  public async queueBulkDelete(): Promise<string> {
    const query = `
      mutation {
        queueBulkDelete
      }`;

    return (await this._httpService.fetchData<any>(query)).queueBulkDelete;
  }
}
